import React, {useState, useEffect} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import PlusCard from "../../assets/images/nissan_pluscard.png"
import GenericVehicle from "../../assets/images/logo-nissan-blanc.png"
import BlackVehicleLogo from "../../assets/images/logo-modal.png"

import axios from "../../components/api/httpclient";
import moment from "moment";

import {Modal} from "react-bootstrap"
import ReactImageFallback from "react-image-fallback";

import { useIntl, Link, FormattedMessage, navigate } from "gatsby-plugin-intl"


class BodyClass extends React.Component {

  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const useForceUpdate = () => useState()[1];
const fuelTypes = [1,2,4];

const MyCars = ({location}) => {
  const [car, setCar] = useState({});
  const [model, setModel] = useState({});
  const [modalShow, setModaleShow] = useState(false);
  const [oneModalShow, setOneModaleShow] = useState(false);

  const forceUpdate = useForceUpdate();

  const intl = useIntl();

  useEffect(() => {
    if (location.state) {
      axios.get(`/cars/` + location.state.id,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then(function (response) {
          axios.get(response.data.carModel,
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
              }
            })
            .then( (m) => {
              if (m.status === 200) {
                if (typeof window !== 'undefined') {
                  localStorage.setItem('car', JSON.stringify({car :response.data, model: m.data}));

                  setCar(response.data);
                  setModel(m.data);
                  forceUpdate();
                }
              } else if (m.status === 401) {
                navigate('/sessions/logout');
              }

            }).catch( (error) => {
            console.log('my-cars model error', error)
          });
        }).catch( (error) => {
        console.log('my-cars car error', error)
      });
    }
    else {
      navigate('/maintenance/timeline');
    }
  }, [location.state])

  const isUpdated = () => {
    let now = moment(new Date());
    let updatedDate = moment(car.updatedDate);

    if (now.diff(updatedDate, 'months') < 5) {
      return true;
    }
    return false;

  }

  const removeCar = (event) => {
    axios.post('/remove-car',
      {carId: car.id},
      {
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      }).then((response) => {
        navigate('/maintenance/timeline');
      }
    );
    event.preventDefault();
  }

  const handleClose = () => {
    setModaleShow(false)
    setOneModaleShow(false)
  }

  const handleShow = () => {
    if(JSON.parse(localStorage.getItem('currentCars')).length == 1) {
      setOneModaleShow(true)
    } else {
      setModaleShow(true)
    }
  }

  const pageContent = (
      <div className="col-lg-8">

        {!isUpdated() ?
          <div className="message-important my-4 mt-lg-0">


            <p className="font-weight-bold">{intl.formatMessage({ id: "timeline.notice_box.1.description" })}</p>
            <p/>
              <div className="text-center">
                <Link to="/mycars/update-car" state={{id: car.id}} className="button mx-auto with-icon"><span
                  className="icon-pencil icon"></span><span>{intl.formatMessage({ id: "generic.forms.form_buttons.update_information" })}</span></Link>
              </div>

          </div>
          :
          <div/>
        }

        <div className="header-vehicle d-md-flex flex-column">

          <div className="flex-md-grow-1 align-items-center d-md-flex text-center text-md-left flex-column">
            {/*<img src={model.image} alt={model.name} className="img-fluid mr-md-4" width="140" />*/}
            <ReactImageFallback
              src={model.image}
              fallbackImage={GenericVehicle}
              alt={model.name}
              className="img-fluid"
              width="140" />
            <div>
              <h1 className="mt-0">{model.name}</h1>
              {/* <div className="fs-20">{car.licencePlate}</div> */}
            </div>
          </div>
          <div className={"d-flex justify-content-center"}>
            <Link to="/mycars/sell-car" state={{id: car.id}} className={`${car.carTransfert ? 'disabled' : ''} d-flex white text-decoration-none doc align-items-center text mr-2`}><i className="icon icon-recall mr-3"></i> <strong>{intl.formatMessage({ id: "timeline.buttons.button_sell_vehicle" })}</strong></Link>
            <Link to="/mycars/services-documentation" state={{id: location.state ? location.state.id : ""}} className="d-flex white text-decoration-none doc align-items-center text"><i className="icon mr-3"></i> <strong>{intl.formatMessage({ id: "generic.car_informations.service_documentation_link" })}</strong></Link>
          </div>
        </div>

        <div className="white-container">
          {car.carTransfert &&
              <div className="alert alert-dark">
                <div>
                  <div>{intl.formatMessage({ id: "car.transfert.invitation_already_sent.text" })}</div>
                  <Link
                      to={`/mycars/cancel-car-selling`}
                      state={{id: car.id}}
                  >
                    {intl.formatMessage({ id: "car.transfert.invitation_already_sent.text_2" })}
                  </Link>
                </div>
              </div>
          }
          <div className="position-relative">
            <h1 className="text-uppercase with-border-top with-border-bottom fs-20">
            {intl.formatMessage({ id: "generic.car_informations.vehicle_information" })}
            </h1>
            <div className="nissan-card">
              { (car.nissanCardId > 0) && (moment(new Date()).diff(car.subscriptionDate, 'days') >= 0) && (moment(new Date()).diff(car.expirationDate, 'days') < 0) &&
                <>
                  <img src={PlusCard} alt={model.name} className="img-fluid" width="80" />
                  <h2>{intl.formatMessage({ id: "generic.car_informations.nissan_plus_card" })}</h2>
                  <p>{intl.formatMessage({ id: "generic.car_informations.nissan_plus_card_exp" })} {moment(car.expirationDate, moment.ISO_8601).format('DD/MM/YYYY')}</p>
                </>
                }
            </div>
          </div>

          <div className="row mb-3 fs-13">
            <div className="col-xl-6 mb-4">
              {/* <span className="d-inline-block w-50 grey-border align-top">
              </span> */}

              <span className="d-inline-block w-50 grey-border align-top">
                {intl.formatMessage({ id: "generic.forms.form_field_labels.number_plate" })}
              </span>
              <span className="d-inline-block w-50 text align-top text-break">
                <strong>{car.licencePlate}</strong>
              </span>
              <span className="d-inline-block w-50 grey-border align-top">
                {intl.formatMessage({ id: "generic.car_informations.chassis_number" })}
              </span>
              <span className="d-inline-block w-50 text align-top text-break">
                <strong>{car.vin}</strong>
              </span>

              <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "generic.car_informations.engine" })}</span>

              <span className="d-inline-block w-50 text align-middle"><strong>{car.engine}</strong></span>

              <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "generic.car_informations.finish" })}</span><span className="d-inline-block w-50 text align-middle"><strong>{car.grade}</strong></span><br />


            </div>
            <div className="col-xl-6 mb-4">

              {  fuelTypes.includes(car.fuel) === true &&
                <span>
                  <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({id: "generic.car_informations.fuel_type"})}</span>
                  <span className="d-inline-block w-50 text align-middle"><strong> {intl.formatMessage({id: "generic.fuel_type." + car.fuel})}</strong></span><br />
                </span>
              }

              { car.firstRegistrationDate !== undefined &&
                <span>
                    <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "generic.car_informations.registration_date" })}</span>
                    <span className="d-inline-block w-50 text align-middle"><strong>{moment(car.firstRegistrationDate, moment.ISO_8601).format('DD/MM/YYYY')}</strong></span>
                </span>
                }

              { car.mileage !== undefined &&
                  <span>
                      <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "generic.car_informations.mileage" })}</span>
                      <span className="d-inline-block w-50 text align-middle"><strong>{car.mileage} KM</strong></span>
                  </span>
              }
            </div>
          </div>

          <Modal size="lg" show={modalShow} onHide={() => handleClose()} animation={false}>
            <Modal.Header className="justify-content-center">
               <h2 className="modal-title text-uppercase flex-grow-1 text-center" id="exampleModalCenterTitle">
                 {intl.formatMessage({ id: "confirm_popin.1.header" })}
               </h2>
               <button type="button" className="close" onClick={() => handleClose()}>
                  <span aria-hidden="true">
                    <span className="icon-close"></span>
                  </span>
                </button>
            </Modal.Header>
            <Modal.Body className="text-center">
              <h2>{intl.formatMessage({ id: "confirm_popin.1.title" })}</h2>
              <br/>
              <Link to="/mycars/transfer-ecarnet" state={{id: car.id}}
                    className={`${car.carTransfert ? 'disabled' : ''} button red mb-4 mb-md-0`}>
                <span>{intl.formatMessage({ id: "confirm_popin.1.button.transfert" })}</span>
              </Link>
              <p className="mt-2">{intl.formatMessage({ id: "confirm_popin.1.description" })}</p>
              <p className="mt-2">{intl.formatMessage({ id: "confirm_popin.1.or" })}</p>
              <button type="button" className="button white" onClick={(event) => removeCar(event)}>
                <span>{intl.formatMessage({ id: "confirm_popin.1.button.delete" })}</span>
              </button>
            </Modal.Body>
          </Modal>

          <Modal size="lg" show={oneModalShow} onHide={() => handleClose()} animation={false}>
            <Modal.Header className="justify-content-center">
              <h2 className="modal-title text-uppercase flex-grow-1 text-center" id="exampleModalCenterTitle">
                {intl.formatMessage({ id: "confirm_popin.1.header" })}
              </h2>
              <button type="button" className="close" onClick={() => handleClose()}>
                  <span aria-hidden="true">
                    <span className="icon-close"></span>
                  </span>
              </button>
            </Modal.Header>
            <Modal.Body className="text-center">
              <h2>{intl.formatMessage({ id: "confirm_popin.1.title" })}</h2>
              <br/>
              <Link to="/mycars/transfer-ecarnet" state={{id: car.id}}
                    className={`${car.carTransfert ? 'disabled' : ''} button red mb-4 mb-md-0`}>
                <span>{intl.formatMessage({ id: "confirm_popin.1.button.transfert" })}</span>
              </Link>
              <p className="mt-2">{intl.formatMessage({ id: "confirm_popin.1.description" })}</p>
              <p className="mt-2">{intl.formatMessage({ id: "confirm_popin.1.or" })}</p>
              <button type="button" className="button white" onClick={(event) => removeCar(event)}>
                <span>{intl.formatMessage({ id: "confirm_popin.1.button.delete" })}</span>
              </button>
            </Modal.Body>
          </Modal>

          <div className="d-flex justify-content-center flex-wrap">
            <Link to="/mycars/update-car"
                  state={{id: car.id}}
                  className="button red with-icon m-2">
              <span className="icon-pencil icon"></span>
              <span className="pl-2">{intl.formatMessage({ id: "generic.forms.form_buttons.update_information" })}</span>
            </Link>
            <button className="button white with-icon m-2" onClick={() =>handleShow()}>
              <span className="icon-car icon-car-del icon"></span>
              <span className="pl-4">{intl.formatMessage({ id: "generic.forms.form_buttons.delete_car" })}</span>
            </button>
          </div>

        </div>
      </div>
    )


  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.mycars.my-cars" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent} currentPage="my-cars" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )

}

export default MyCars
